e4.extranet = {
    init: function () {
        e4.extranet.login.remember();
    },
    login: {
        remember: function () {
            jQuery( '.js-e-extranet-login-remember' ).on( 'change', function () {
                jQuery( this.form ).find( '.js-e-extranet-login-remember-username, .js-e-extranet-login-remember-password' ).val( ( jQuery( this ).prop( 'checked' ) === true ? 'True' : '' ) );
            } ).triggerHandler( 'change' );
        }
    }
};

