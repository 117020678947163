e4.scrollToAnchor = {
    init: function () {
        e4.scrollToAnchor.animating = false;
        e4.scrollToAnchor.goToLocationHash();
        e4.scrollToAnchor.bindEvents();
        e4.scrollToAnchor.backToTop.init();
    },
    goToLocationHash: function () {
        if ( location.hash !== '' && location.hash !== '#' && location.hash.indexOf('/') === -1 ) {
            var intScrollTarget = e4.scrollToAnchor.getTarget( location.hash );
            if ( intScrollTarget > -1 ) {
                e4.scrollToAnchor.animate( intScrollTarget );
                e4.navigation.sticky.hide();
            }
        }
    },
    bindEvents: function () {
        jQuery( 'a[href*="#"]' ).not( 'a[data-toggle],a[data-slide]' ).on( 'click', function ( event ) {
            if ( this.hash === '#top' ) {
                event.preventDefault();
                e4.scrollToAnchor.animate( 0 );
            }
            else if ( location.pathname.replace( /^\//, '' ) === this.pathname.replace( /^\//, '' ) && location.hostname === this.hostname ) {
                event.preventDefault();
                e4.scrollToAnchor.animate( e4.scrollToAnchor.getTarget( this.hash ) );
            }
        } );
    },
    getTarget: function ( hash ) {
        var jWindow = jQuery( window ),
            jTarget = jQuery( hash ),
            jTargetSection = jTarget.closest( '.e-section' ),
            intScrollTarget = -1;
        if ( jTarget.length === 1 ) {
            if ( jTargetSection.is( '.js-e-scroll-reveal .e-section' ) === true ) {
                jTargetSection.addClass( e4.settings.classes.resetTransform );
            }
            intScrollTarget = jTarget.offset().top;
            if ( jTargetSection.is( '.p-section-fullscreen, .p-banner-fullscreen' ) === false ) {
                intScrollTarget -= jWindow.height() / 100 * e4.settings.scrollToAnchor.nudge;
            }
            if ( jQuery( '.js-e-page-auto-hide-menu' ).length === 1 ) {
                e4.settings.scrollToAnchor.stickyElements = e4.settings.scrollToAnchor.stickyElements.replace( '.js-e-header', '' );
            }
            jQuery( e4.settings.scrollToAnchor.stickyElements ).each( function () {
                intScrollTarget -= jQuery( this ).height();
            } );
            intScrollTarget = Math.max( intScrollTarget, 0 );
        }
        return intScrollTarget;
    },
    animate: function ( intScrollTarget ) {
        if ( intScrollTarget > -1 ) {
            if ( e4.scrollToAnchor.animating === false ) {
                e4.scrollToAnchor.animating = true;
                jQuery( 'html,body' ).animate( {
                    scrollTop: intScrollTarget
                }, Math.floor( Math.abs( jQuery( window ).scrollTop() - intScrollTarget ) * e4.settings.scrollToAnchor.speed ), e4.settings.scrollToAnchor.easing, function () {
                    e4.scrollToAnchor.animating = false;
                } );
            }
        }
    },
    backToTop: {
        init: function () {
            if ( jQuery( '.js-e-page-back-to-top' ).length === 1 ) {
                e4.scrollToAnchor.backToTop.requestingAnimation = false;
                e4.scrollToAnchor.backToTop.bindEvents();
            }
        },
        bindEvents: function () {
            jQuery( window ).on( 'scroll resize', function () {
                if ( e4.scrollToAnchor.backToTop.requestingAnimation === false ) {
                    window.requestAnimationFrame( function () {
                        e4.scrollToAnchor.backToTop.toggleVisibility();
                        e4.scrollToAnchor.backToTop.requestingAnimation = false;
                    } );
                }
                e4.scrollToAnchor.backToTop.requestingAnimation = true;
            } );
        },
        toggleVisibility: function () {
            var jWindow = jQuery( window ),
                windowScrollTop = jWindow.scrollTop(),
                jBackToTopLink = jQuery( '.js-e-back-to-top' );
            e4.scrollToAnchor.scrollDirectionCurrent = windowScrollTop > e4.scrollToAnchor.lastScrollTop ? 'down' : 'up';
            if ( e4.scrollToAnchor.scrollDirectionCurrent !== e4.scrollToAnchor.scrollDirectionNew ) {
                if ( windowScrollTop > jWindow.height() * e4.settings.scrollToAnchor.backToTop.revealOffset ) {
                    jBackToTopLink.addClass( 'is-visible' );
                }
                else {
                    jBackToTopLink.removeClass( 'is-visible' );
                }
            }
            e4.scrollToAnchor.scrollDirectionLast = e4.scrollToAnchor.scrollDirectionCurrent;
        }
    }
};

