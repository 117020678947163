e4.ecom.product = {
    init: function () {
        e4.ecom.product.bindEvents();
        e4.ecom.product.initCollapse();
        if ( jQuery( '.js-e-product-form' ).length === 1 ) {
            e4.util.require.js( e4.settings.require.handlebars, function () {
                //e4.ecom.product.handlebars.registerHelpers();
                e4.ecom.product.handlebars.compileTemplate();
                //e4.ecom.product.bindEvents();
            } );
        }
    },
    bindEvents: function () {
        jQuery( '.js-e-product-form' ).on( 'submit', function ( event ) {
            e4.ecom.product.addToCart.submit( event );
        } );

        jQuery( '#js-e-cart-msg' ).on( 'click', function ( event ) {
            ////console.log(event.target.getAttribute( 'data-dismiss' ));
            if ( jQuery( this ).hasClass( 'is-active' ) === true && ( event.target.getAttribute( 'data-dismiss' ) === 'msg' || event.target.parentNode.getAttribute( 'data-dismiss' ) === 'msg' ) ) {
                e4.ecom.product.addToCart.msg.hide();
            }
            event.stopPropagation();
        } ).on( 'mouseover', e4.ecom.product.addToCart.msg.clearTimeout ).on( 'mouseleave', {
            timeout: e4.settings.ecom.addToCart.msgHideDelay / 3
        }, e4.ecom.product.addToCart.msg.setTimeout );

        jQuery( '.collapse-peek' ).on( 'click', '.collapse:not(.in)', function () {
            jQuery( this ).collapse( 'show' );
        } );
    },
    initCollapse: function () {
        jQuery( '.collapse-peek-content' ).each( function () {
            var jThis = jQuery( this );
            if ( this.scrollHeight <= jThis.height() * 1.25 ) {
                jThis.closest( '.collapse-peek' ).addClass( 'collapse-peek-disabled' ).find( '[data-toggle="collapse"]' ).removeAttr( 'data-toggle' );
                jThis.collapse( 'show' );
            }
        } );
    },
    //setLoading
    //sendRequest
    //handleResponse
    //  success
    //      animate button
    //      animate mini cart
    //      show confirm modal
    //  error
    //      animate button
    //      show error modal
    //  heal button
    //  hide modal

    addToCart: {
        msg: {
            timeout: null,
            show: function () {
                e4.ecom.product.addToCart.msg.clearTimeout();
                var jMsg = jQuery( '#js-e-cart-msg' ),
                    jHeader = jQuery( '.js-e-header' );
                jMsg.css( 'top', function () {
                    var intPosTop = 0;
                    if ( jHeader.hasClass( 'is-hidden' ) === false ) {
                        intPosTop += jHeader.height();
                    }
                    intPosTop += jHeader.offset().top;
                    return Math.floor( intPosTop );
                } );
                jMsg.addClass( 'is-active' );
                e4.ecom.product.addToCart.msg.setTimeout();
                jQuery( document ).on( 'click', e4.ecom.product.addToCart.msg.hide );
            },
            hide: function () {
                e4.ecom.product.addToCart.msg.clearTimeout();
                jQuery( document ).off( 'click', e4.ecom.product.addToCart.msg.hide );
                jQuery( '#js-e-cart-msg' ).removeClass( 'is-active' );
            },
            setTimeout: function ( event ) {
                //console.log( event, 'setTimeout' );
                //TODO: check for mobile
                var timeout = e4.settings.ecom.addToCart.msgHideDelay;
                if ( event !== undefined && event.data !== undefined ) {
                    timeout = Math.round( event.data.timeout || timeout );
                }
                e4.ecom.product.addToCart.msg.timeout = setTimeout( e4.ecom.product.addToCart.msg.hide, timeout );
            },
            clearTimeout: function ( event ) {
                //console.log( event, 'clearTimeout' );
                if ( e4.ecom.product.addToCart.msg.timeout ) {
                    e4.ecom.product.addToCart.msg.timeout = clearTimeout( e4.ecom.product.addToCart.msg.timeout );
                }
            }
        },
        submit: function ( event ) {
            event.preventDefault();
            //e4.ecom.product.addToCart.loadingShow();
            var jForm = jQuery( event.target ),
                jqXHR = jQuery.ajax( {
                    type: jForm.attr( 'method' ),
                    url: jForm.attr( 'action' ),
                    data: jForm.serialize(),
                    cache: false,
                    dataType: 'json',
                    context: event.target
                } );
            jqXHR.done( function ( data ) {
                e4.ecom.product.addToCart.success( data );
            } );
            jqXHR.fail( function ( data, textStatus, errorThrown ) {
                e4.ecom.product.addToCart.error( data );
            } );
            jqXHR.always( function ( data ) {
                //e4.ecom.product.addToCart.loadingHide();
                //console.log( 'always', data );
            } );
        },
        success: function ( data ) {
            //  success
            //      animate button
            //      animate mini cart
            //      show confirm modal
            //console.log( 'done', data );
            jQuery( '#js-e-cart-msg' ).find( '.js-e-cart-msg-content' ).html( e4.ecom.product.handlebars.tmpl.msgContent( data.cart ) );
            e4.ecom.product.addToCart.msg.show();
            jQuery( '.e-cart-mini .e-cart-count' ).html( data.cart.EcomOrderOrderLinesTotalProductQuantity );
        },
        error: function ( data, textStatus, errorThrown ) {
            //  error
            //      animate button
            //      show error modal
            //console.log( 'fail', data, textStatus, errorThrown );

        }
    },
    handlebars: {
        tmpl: {},
        compileTemplate: function () {
            var tmpl = jQuery( '#js-handlebars-tmpl-cart-msg-content' ).html();
            e4.ecom.product.handlebars.tmpl.msgContent = Handlebars.compile( tmpl );
        }
    }
};

