
var partnerrev = partnerrev || {};

( function () {
    'use strict';
    jQuery( function () {
        partnerrev.init();
    } );

    partnerrev.init = function () {
        partnerrev.cookie.init();
        partnerrev.maps.init();
        partnerrev.customSearchBar.init();
        jQuery('.nav-link.dropdown-toggle').on('click',function(e){
            e.preventDefault();
        });
    };

    partnerrev.cookie = {};
    partnerrev.cookie.init = function () {
        var jCookieMessage = jQuery( '.e-cookie-message' );
        if ( jCookieMessage.length === 1 ) {
            jCookieMessage.find( '.CookieOptInLevelConfig' ).on( 'click', function ( event ) {
                event.preventDefault();
                jQuery.get( this.href );
                jCookieMessage.addClass( "accepted" );
            } );
        }
    };

    partnerrev.maps = {};
    partnerrev.maps.loadAPI = function ( strCallback ) {
        var gm = document.createElement( 'script' ),
            script = document.getElementsByTagName( 'script' )[ 0 ];
        gm.src = '//maps.google.com/maps/api/js?v=3&key=AIzaSyAChsn3GFIXqm_X5LEyrWPG7cfHPVBzvRI&callback=' + strCallback;
        script.parentNode.insertBefore( gm, script );
    };
    partnerrev.maps.init = function () {
        var jGoogleMaps = jQuery( '.e-google-map' );
        if ( jGoogleMaps.length > 0 ) {
            partnerrev.maps.loadAPI( 'partnerrev.maps.load' );
        }
    };
    partnerrev.maps.load = function () {
        var jGoogleMaps = jQuery( '.e-google-map' );
        //var jSearchMarkerSettings = jQuery( '#js-e-maps-search-marker-settings' );
        if ( jGoogleMaps.length > 0 ) {
            jGoogleMaps.each( function ( index, element ) {
                var jGoogleMap = jQuery( element );
                // Save map settings on Map object
                jGoogleMap.data( 'settings', {
                    mapData: jGoogleMap.find( 'code.e-google-map-data' ),
                    mapZoom: ( jGoogleMap.data( 'zoom' ) !== undefined ? jGoogleMap.data( 'zoom' ) : 10 ),
                    mapRatio: ( jGoogleMap.data( 'ratio' ) !== undefined ? jGoogleMap.data( 'ratio' ).split( ':' ) : '' ),
                    //mapColor: e.util.rgb2hex( ( jGoogleMap.data( 'color' ) !== undefined ? jGoogleMap.data( 'color' ) : jGoogleMap.css( 'color' ) ) ).replace( /^\#/, '' ),
                    //mapDefaultMarkerColor: e.util.rgb2hex( ( jGoogleMap.find( 'code.e-google-map-data' ).first().css( 'color' ) !== undefined ? jGoogleMap.find( 'code.e-google-map-data' ).first().css( 'color' ) : jGoogleMap.css( 'color' ) ) ).replace( /^\#/, '' ),
                    //mapSearchIconColor: ( jSearchMarkerSettings.length > 0 ? e.util.rgb2hex( jSearchMarkerSettings.css( 'color' ) ).replace( /^\#/, '' ) : '' ),
                    mapMaxZoomLevel: ( jGoogleMap.data( 'max-zoom-level' ) !== undefined ? jGoogleMap.data( 'max-zoom-level' ) : 12 ),
                    mapMinSearchRadius: ( jGoogleMap.data( 'min-search-radius' ) !== undefined ? jGoogleMap.data( 'min-search-radius' ) : 20 )
                } );
                if ( jGoogleMap.data( 'settings' ).mapRatio !== '' && jGoogleMap.data( 'settings' ).mapRatio.length === 2 ) {
                    jGoogleMap.css( 'padding-bottom', ( parseInt( jGoogleMap.data( 'settings' ).mapRatio[ 1 ] ) / parseInt( jGoogleMap.data( 'settings' ).mapRatio[ 0 ] ) * 100 ) + '%' );
                }
                var mapOptions = {
                    center: new google.maps.LatLng( 56.26392, 9.501785 ), //DK
                    scrollwheel: false,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    mapTypeControl: false,
                    zoom: jGoogleMap.data( 'settings' ).mapZoom,
                    styles: [ {
                        stylers: [
                            //{ visibility: 'simplified' }
                        ]
                    }, {
                        featureType: 'road',
                        stylers: [ {
                            visibility: 'on'
                        }, {
                            //hue: mapColor
                        }, {
                            lightness: 10
                        }, {
                            saturation: -10
                        } ]
                    }, {
                        featureType: 'landscape',
                        stylers: [ {
                            lightness: 30
                        }, {
                            saturation: -70
                        } ]
                    }, {
                        featureType: 'water',
                        stylers: [ {
                            lightness: 50
                        }, {
                            saturation: -50
                        } ]
                    } ]
                };
                jGoogleMap.gmap( mapOptions ).bind( 'init', function () {
                    if ( jGoogleMap.is( '#js-e-map' ) === true ) {
                        jGoogleMap.gmap( 'microdata', 'http://schema.org/Organization', function ( result, item, index ) {
                            partnerrev.maps.helpers.addMicroDataMarkers( result, item, index, jGoogleMap );
                        } );
                        // Init dealer search
                        partnerrev.dealersearch.init();
                    }
                    else {
                        //Add single marker
                        var addr = jQuery( jGoogleMap.data( 'settings' ).mapData ).text();
                        addr = decodeURIComponent( addr.replace( /^http:\/\//, '' ).replace( /[\(zoom,ratio=:0-9\)\s\%]+$/i, '' ) );
                        partnerrev.maps.helpers.addAddressMarker( jGoogleMap, addr );
                    }
                } );
            } );
        }
    };
    partnerrev.maps.helpers = {};
    partnerrev.maps.helpers.icon = function ( jGoogleMap, char, color ) {
        var url = 'Files/Templates/Designs/partnerrevision/assets/img/dealer-marker.png';
        return url;
    };
    partnerrev.maps.helpers.addAddressMarker = function ( jGoogleMap, address ) {
        var markerColor = jGoogleMap.data( 'settings' ).mapDefaultMarkerColor;
        var gGeocoder = new google.maps.Geocoder();
        gGeocoder.geocode( {
            address: address
        }, function ( results, status ) {
            if ( status === google.maps.GeocoderStatus.OK ) {
                var latlng = results[ 0 ].geometry.location;
                //console.log( latlng );
                var gMarkerOptions = {
                    bounds: false, // Special to jQuery.ui.map
                    position: latlng,
                    icon: partnerrev.maps.helpers.icon( jGoogleMap, '', markerColor )
                };
                jGoogleMap.gmap( 'addMarker', gMarkerOptions );
                var map = jGoogleMap.gmap( 'get', 'map' );
                map.setCenter( latlng );
            }
        } );
    };
    partnerrev.maps.helpers.addMicroDataMarkers = function ( result, item, index, jGoogleMap ) {
        //var jMicroDataElement = jQuery( item );
        //var jMarkerData = jMicroDataElement.find( '.js-e-maps-marker-data' );
        var markerColor = '#cccccc'/*e.util.rgb2hex( jMarkerData.css( 'color' ) ).replace( /^\#/, '' )*/;
        // Parse coordinates
        var lat = parseFloat( result.geo[ 0 ].latitude );
        var lng = parseFloat( result.geo[ 0 ].longitude );
        if ( isNaN( lat ) || isNaN( lng ) || lat === 0 || lng === 0 ) {
            jGoogleMap.after( '<p> Error - lat/lng data not valid for this address: ' + result.name + ' </p>' );
            return;
        }
        if ( lat !== undefined && lng !== undefined ) {
            var latlng = new google.maps.LatLng( lat, lng );
            var gMarkerOptions = {
                bounds: true, // Special to jQuery.ui.map
                position: latlng,
                icon: partnerrev.maps.helpers.icon( jGoogleMap, '', markerColor )
            };
            var jMarker = jGoogleMap.gmap( 'addMarker', gMarkerOptions, function ( map, marker ) {
                // find correct element and map it to marker
                var jDealerElement = jQuery( item );
                // Add reference to dealer DOM element on Marker
                marker.dealer = item;
                // When clicking the dealer element, do what ever happens on marker click
                var jClickElement = jDealerElement.find( '.js-e-map-list-dealer-name' );
                jClickElement.on( 'click', function () {
                    jQuery( marker ).triggerEvent( 'click' );
                    return false;
                } );
            } );
            // Add event to Markers
            jMarker.click( function () {
                partnerrev.maps.helpers.markerClick( jGoogleMap, this );
            } );
        }
    };
    partnerrev.maps.helpers.markerClick = function ( jGoogleMap, marker ) {
        var jDealer = jQuery( marker.dealer );
        // Build content for infowindow
        var infoWindowContent = jDealer.find( '.js-e-maps-infowindow-content' ).html();
        jGoogleMap.gmap( 'openInfoWindow', {
            'content': infoWindowContent,
            maxWidth: 600
        }, marker );
        //e.util.scroll.toElement( jGoogleMap, 0, true, false );
        // Hook up to dealersearch if it's defined
        if ( partnerrev.dealersearch.markerClicked !== undefined ) {
            partnerrev.dealersearch.markerClicked();
        }
    };

    partnerrev.dealersearch = {};
    partnerrev.dealersearch.settings = {
        fields: {
            jInput: jQuery( '#js-e-map-search-input' ),
            jSubmit: jQuery( '#js-e-map-search-submit' ),
            jReset: jQuery( '#js-e-map-search-reset' )
        },
        delay: {
            fast: 200,
            slow: 250
        }
    };
    partnerrev.dealersearch.primaryCountry = '';
    partnerrev.dealersearch.initialZoom = true;
    partnerrev.dealersearch.jDealerMap = null;
    partnerrev.dealersearch.typeahead = null;
    partnerrev.dealersearch.suggestIsAboutToFire = undefined;
    partnerrev.dealersearch.searchpositionMarker = undefined;
    partnerrev.dealersearch.datasource = [];
    /// Init dealer search logic
    partnerrev.dealersearch.init = function () {
        // Find dealersearch map
        var jGoogleMap = jQuery( '#js-e-map:visible' );
        partnerrev.dealersearch.jDealerMap = jGoogleMap;
        if ( jGoogleMap.length > 0 ) {
            // Read settings from map
            partnerrev.dealersearch.maxZoomLevelOnFitBounds = jGoogleMap.data( 'max-zoom-level' ) || 12;
            partnerrev.dealersearch.minSearchRadius = jGoogleMap.data( 'min-search-radius' ) || 20;
            // Determine country
            partnerrev.dealersearch.primaryCountry = partnerrev.dealersearch.determineCountry();
            // Attach events
            partnerrev.dealersearch.attachEvents();
            // Set focus in inputfield
            partnerrev.dealersearch.settings.fields.jInput.focus();
        }
    };
    /// Attach events to dom elements
    partnerrev.dealersearch.attachEvents = function () {
        //partnerrev.dealersearch.bindTypeahead();
        // Search button
        //partnerrev.dealersearch.settings.fields.jSubmit.on( 'click', function ( event ) {
        //    event.preventDefault();
        //    partnerrev.dealersearch.performSearch( partnerrev.dealersearch.settings.fields.jInput.val(), '', true );
        //} );
        // Reset button
        partnerrev.dealersearch.settings.fields.jReset.on( 'click', function ( event ) {
            event.preventDefault();
            partnerrev.dealersearch.resetMap( {
                clearSearchfield: true
            } );
        } );
        // Event when map was dragged
        google.maps.event.addListener( partnerrev.dealersearch.jDealerMap.gmap( 'get', 'map' ), 'dragend', function () {
            // 300 ms after the center of the map has changed, pan back to the marker.
            window.setTimeout( function () {
                partnerrev.dealersearch.syncViewPort();
            }, 300 );
        } );
        // Event when zoom is changed
        google.maps.event.addListener( partnerrev.dealersearch.jDealerMap.gmap( 'get', 'map' ), 'zoom_changed', function () {
            // 300ms after the center of the map has changed, pan back to the marker.
            window.setTimeout( function () {
                partnerrev.dealersearch.syncViewPort();
            }, 300 );
            // When zoom changes, hook up to bounds changed, and override zoom level
            var zoomChangeBoundsListener = google.maps.event.addListenerOnce( partnerrev.dealersearch.jDealerMap.gmap( 'get', 'map' ), 'bounds_changed', function () {
                if ( this.getZoom() > partnerrev.dealersearch.maxZoomLevelOnFitBounds && partnerrev.dealersearch.initialZoom === true ) {
                    // Change max/min zoom here
                    this.setZoom( partnerrev.dealersearch.maxZoomLevelOnFitBounds );
                    partnerrev.dealersearch.initialZoom = false;
                }
                google.maps.event.removeListener( zoomChangeBoundsListener );
            } );
        } );
        // Listen to window resize
        jQuery('window').on( 'resize', function () {
            partnerrev.dealersearch.resetMap();
        } );
    };
    partnerrev.dealersearch.markerClicked = function () {
        // Hide reset button
        partnerrev.dealersearch.settings.fields.jReset.removeClass( 'hidden' );
    };
    /// Determine primary country for search
    partnerrev.dealersearch.determineCountry = function () {
        // Determine primary country to append to each google request
        var prevCountry = '';
        jQuery( '.js-e-maps-country' ).each( function ( idx ) {
            var country = jQuery.trim( jQuery( this ).text() );
            // if all countries are not the same, return an empty string
            if ( idx > 0 && prevCountry !== country ) {
                prevCountry = '';
                return;
            }
            prevCountry = country;
        } );
        return prevCountry;
    };
    /// Render error when search fails
    partnerrev.dealersearch.renderNoResultsMessage = function ( message ) {
        var text = partnerrev.dealersearch.getNoResultsText( message );
        jQuery( '#js-e-maps-search-information-output' ).html( text );
    };
    /// Render error when search fails
    partnerrev.dealersearch.renderGoogleMapsError = function () {
        var errorText = jQuery( '#js-e-maps-search-information-error' ).html();
        jQuery( '#js-e-maps-search-information-output' ).html( errorText );
    };
    /// Clear error when search fails
    partnerrev.dealersearch.clearError = function () {
        jQuery( '#js-e-maps-search-information-output' ).html( '' );
    };
    partnerrev.dealersearch.getNoResultsText = function ( replaceText ) {
        var textPart1 = jQuery( '#js-e-maps-search-information-noresults-start' ).text(),
            textPart2 = jQuery( '#js-e-maps-search-information-noresults-end' ).text(),
            text = textPart1 + ' ' + replaceText + ' ' + textPart2;
        return text;
    };
    // Get URL for searchresult icon
    partnerrev.dealersearch.getSearchIconUrl = function ( jGoogleMap ) {
        var url = '//chart.googleapis.com/chart?chst=d_simple_text_icon_left&chld=|14|000|glyphish_target|24';
        var fill_color = jGoogleMap.data( 'settings' ).mapSearchIconColor;
        return url + '|' + fill_color;
    };
    /// Bind typeahead to field
    partnerrev.dealersearch.bindTypeahead = function () {
        partnerrev.dealersearch.typeahead = partnerrev.dealersearch.settings.fields.jInput.typeahead( {
            menu: '<ul class="typeahead nav dropdown-menu js-e-typeahead-dropdown"></ul>',
            source: function ( query, process ) {
                // Determine how fast we should start searching.
                var doFastSearch = false;
                // Test for zipcode
                var zipcodeRegex = /^[0-9]{4}/;
                if ( query.match( zipcodeRegex ) ) {
                    doFastSearch = true;
                }
                // Test for space 'Strøget 40'
                if ( query.indexOf( ' ' ) > 4 ) {
                    doFastSearch = true;
                }
                // Suggest with long words
                if ( query.length > 10 ) {
                    doFastSearch = true;
                }
                // Throttle
                if ( partnerrev.dealersearch.suggestIsAboutToFire ) {
                    clearTimeout( partnerrev.dealersearch.suggestIsAboutToFire );
                }
                partnerrev.dealersearch.suggestIsAboutToFire = setTimeout( function () {
                    partnerrev.dealersearch.performSearch( query, partnerrev.dealersearch.primaryCountry, false, function ( data ) {
                        return process( data );
                    }, false );
                }, doFastSearch ? partnerrev.dealersearch.settings.delay.fast : partnerrev.dealersearch.settings.delay.slow );
            },
            matcher: function ( item ) {
                return item;
            },
            updater: function ( item ) {
                // Show reset button
                partnerrev.dealersearch.settings.fields.jReset.removeClass( 'hidden' );
                partnerrev.dealersearch.performSearch( item, '', true, undefined, false );
                return item;
            }
        } );
    };
    /// Perform search
    partnerrev.dealersearch.performSearch = function ( originalSearchValue, appendToSearchValue, updateMap, callback, recursiveCall ) {
        // Clean up original search value for spaces and dirt
        var searchValueBeforeCountryAppend = originalSearchValue.replace( /[|&;$%@"<>()+, ]/g, '' );
        var searchValue = originalSearchValue;
        // Append country to searchvalue if we could determine a primary country
        if ( appendToSearchValue.length > 0 ) {
            searchValue = searchValueBeforeCountryAppend + ' ,' + appendToSearchValue;
        }
        if ( searchValueBeforeCountryAppend.length > 3 ) {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode( {
                address: searchValue
            }, function ( results, status ) {
                if ( status === google.maps.GeocoderStatus.OK ) {
                    // Clear error message if it's being viewed
                    partnerrev.dealersearch.clearError();
                    var searchPosition = results[ 0 ].geometry.location;
                    // Update suggested addresses
                    partnerrev.dealersearch.datasource.length = 0;
                    for ( var i = 0; i < results.length; i++ ) {
                        partnerrev.dealersearch.datasource.push( results[ i ].formatted_address );
                    }
                    if ( updateMap ) {
                        // find marker closest to search address
                        var markersOnMap = partnerrev.dealersearch.jDealerMap.gmap( 'get', 'markers' );
                        var closestMarkers = partnerrev.dealersearch.helpers.findClosest( searchPosition, markersOnMap );
                        // remove all search markers
                        partnerrev.dealersearch.removeSearchMarkers();
                        // add search marker
                        var gMarkerOptions = {
                            bounds: true, // gmap specific
                            position: searchPosition,
                            icon: partnerrev.dealersearch.getSearchIconUrl( partnerrev.dealersearch.jDealerMap ),
                            zIndex: -1
                        };
                        partnerrev.dealersearch.addSearchMarker( gMarkerOptions );
                        // Clear bounds and fit search location and results in view
                        var bounds = new google.maps.LatLngBounds();
                        bounds.extend( searchPosition );
                        // Loop markers within range
                        jQuery.each( closestMarkers, function ( index, marker ) {
                            // Animate closest marker
                            marker.setAnimation( google.maps.Animation.DROP );
                            bounds.extend( marker.getPosition() );
                        } );
                        var map = partnerrev.dealersearch.jDealerMap.gmap( 'get', 'map' );
                        partnerrev.dealersearch.initialZoom = true;
                        map.fitBounds( bounds );
                        // Hide markers not visible on map
                        partnerrev.dealersearch.syncViewPort();
                        // Show reset button
                        partnerrev.dealersearch.settings.fields.jReset.removeClass( 'hidden' );
                    }
                    // Return result if a callback is present
                    if ( callback ) {
                        callback( partnerrev.dealersearch.datasource );
                    }
                }
                // If no results were found
                else if ( status === google.maps.GeocoderStatus.ZERO_RESULTS ) {
                    partnerrev.dealersearch.datasource.length = 0;
                    if ( recursiveCall === false ) {
                        partnerrev.dealersearch.performSearch( originalSearchValue, '', false, undefined, true );
                        return;
                    }
                    if ( updateMap ) {
                        partnerrev.dealersearch.renderNoResultsMessage( originalSearchValue );
                    }
                    // Reset viewport
                    partnerrev.dealersearch.resetMap();
                    if ( callback ) {
                        callback( partnerrev.dealersearch.datasource );
                    }
                }
                // Handle errors
                else if ( status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT ) {
                    partnerrev.dealersearch.renderGoogleMapsError();
                    partnerrev.dealersearch.helpers.trackInGoogle( 'Over query limit', searchValue );
                }
                else if ( status === google.maps.GeocoderStatus.ERROR ) {
                    partnerrev.dealersearch.renderGoogleMapsError();
                    partnerrev.dealersearch.helpers.trackInGoogle( 'Error', searchValue );
                }
                else if ( status === google.maps.GeocoderStatus.INVALID_REQUEST ) {
                    partnerrev.dealersearch.renderGoogleMapsError();
                    partnerrev.dealersearch.helpers.trackInGoogle( 'Invalid request', searchValue );
                }
                else if ( status === google.maps.GeocoderStatus.REQUEST_DENIED ) {
                    partnerrev.dealersearch.renderGoogleMapsError();
                    partnerrev.dealersearch.helpers.trackInGoogle( 'Request denied', searchValue );
                }
                else if ( status === google.maps.GeocoderStatus.UNKNOWN_ERROR ) {
                    partnerrev.dealersearch.renderGoogleMapsError();
                    partnerrev.dealersearch.helpers.trackInGoogle( 'Unknown error', searchValue );
                }
            } );
        }
        else {
            // Reset viewport
            partnerrev.dealersearch.resetMap();
        }
    };
    partnerrev.dealersearch.removeSearchMarkers = function () {
        var markersOnMap = partnerrev.dealersearch.jDealerMap.gmap( 'get', 'markers' );
        var bounds = new google.maps.LatLngBounds();
        // Fit bounds on all markers on map
        // - build new bounds that does not contain search marker
        // - make new list of markers that doesn't container search icon
        var newMarkerList = [];
        jQuery.each( markersOnMap, function () {
            if ( this.searchResult !== true ) {
                newMarkerList.push( this );
                bounds.extend( this.getPosition() );
            }
            else {
                this.setMap( null );
            }
        } );
        partnerrev.dealersearch.jDealerMap.gmap( 'set', 'bounds', bounds );
        partnerrev.dealersearch.jDealerMap.gmap( 'set', 'markers', newMarkerList );
    };
    partnerrev.dealersearch.addSearchMarker = function ( gMarkerOptions ) {
        // Add info to marker so we can find and remove it later
        gMarkerOptions.searchResult = true;
        partnerrev.dealersearch.jDealerMap.gmap( 'addMarker', gMarkerOptions, function ( /*map, marker*/) {} );
    };
    partnerrev.dealersearch.syncViewPort = function () {
        if ( partnerrev.dealersearch.resetInProgress !== true ) {
            // Hide list with dealers
            jQuery( '#js-e-map-list' ).removeClass( 'hidden' );
            // Hide all dealer list items
            jQuery( '.js-e-map-list-item' ).addClass( 'hidden' );
            // Hide alle dealer liste items that are not in view
            var markersOnMap = partnerrev.dealersearch.jDealerMap.gmap( 'get', 'markers' );
            var markersInView = 0;
            jQuery.each( markersOnMap, function () {
                var markerIsInView = partnerrev.dealersearch.jDealerMap.gmap( 'inViewport', this );
                if ( markerIsInView === true ) {
                    var jDealer = jQuery( this.dealer );
                    jDealer.removeClass( 'hidden' );
                    markersInView++;
                }
            } );
            // If no items in view, hide dealer list container
            if ( markersInView === 0 ) {
                jQuery( '#js-e-map-list' ).addClass( 'hidden' );
            }
        }
        partnerrev.dealersearch.resetInProgress = false;
    };
    partnerrev.dealersearch.resetMap = function ( options ) {
        // Hide list of dealers
        jQuery( '#js-e-map-list' ).addClass( 'hidden' );
        // Close all infowindows
        partnerrev.dealersearch.jDealerMap.gmap( 'closeInfoWindow' );
        // Remove search marker
        partnerrev.dealersearch.removeSearchMarkers();
        // Set variable to indicate if maps is being reset
        partnerrev.dealersearch.resetInProgress = true;
        // Hide reset button
        partnerrev.dealersearch.settings.fields.jReset.addClass( 'hidden' );
        if ( options !== undefined ) {
            if ( options.clearSearchfield === true ) {
                partnerrev.dealersearch.settings.fields.jInput.val( '' );
            }
        }
        // re-fit bounds of map
        partnerrev.dealersearch.jDealerMap.gmap( 'get', 'map' ).fitBounds( partnerrev.dealersearch.jDealerMap.gmap( 'get', 'bounds' ) );
        // Set focus in inputfield
        partnerrev.dealersearch.settings.fields.jInput.focus();
    };
    partnerrev.dealersearch.helpers = {};
    partnerrev.dealersearch.helpers.findRadius = function ( x ) {
        return x * Math.PI / 180;
    };
    partnerrev.dealersearch.helpers.findClosest = function ( source, markers ) {
        var markersWithinRange = [];
        var lat = source.lat();
        var lng = source.lng();
        var R = 6371; // radius of earth in km
        var distances = [];
        var closest = null;
        jQuery.each( markers, function ( index, marker ) {
            if ( marker.searchResult === undefined ) { // Skip markers that are not dealers
                var mlat = marker.getPosition().lat();
                var mlng = marker.getPosition().lng();
                var dLat = partnerrev.dealersearch.helpers.findRadius( mlat - lat );
                var dLong = partnerrev.dealersearch.helpers.findRadius( mlng - lng );
                var a = Math.sin( dLat / 2 ) * Math.sin( dLat / 2 ) + Math.cos( partnerrev.dealersearch.helpers.findRadius( lat ) ) * Math.cos( partnerrev.dealersearch.helpers.findRadius( lat ) ) * Math.sin( dLong / 2 ) * Math.sin( dLong / 2 );
                var c = 2 * Math.atan2( Math.sqrt( a ), Math.sqrt( 1 - a ) );
                var d = R * c;
                distances[ index ] = d;
                if ( closest === null || d < distances[ closest ] ) {
                    closest = index;
                }
            }
        } );
        // Distance to closest marker
        markersWithinRange.length = 0;
        var distanceToClosestMarker = distances[ closest ];
        var distanceToClosestMarkerWithFaktor = distanceToClosestMarker * 1.3;
        if ( distanceToClosestMarkerWithFaktor < partnerrev.dealersearch.minSearchRadius ) {
            distanceToClosestMarkerWithFaktor = partnerrev.dealersearch.minSearchRadius;
        }
        for ( var i = 0; i < markers.length; i++ ) {
            var markerDistanceToCenter = distances[ i ];
            if ( markerDistanceToCenter < distanceToClosestMarkerWithFaktor ) {
                markersWithinRange.push( markers[ i ] );
            }
        }
        //return markers[ closest ];
        return markersWithinRange;
    };
    partnerrev.customSearchBar = {};
    partnerrev.customSearchBar.init = function ( ) {
        jQuery('.e-search-form').on('submit', function(e){
            e.preventDefault();
            var customSearchBarValue = jQuery('.custom-search-input').val();
            jQuery('.js-e-search .js-e-search-input').val(customSearchBarValue).trigger('input');
            jQuery( '.js-e-search' ).trigger('shown.bs.modal');
        });
        jQuery('.js-trigger-search').on('click', function(e){
            e.preventDefault();
            jQuery('.e-search-form').trigger('submit');
        });
    };
    e4.p.card.bindEvents = function(){
        //do nothing
    }
}() );

