e4.search = {
    init: function () {
        //load handlebars
        //compile tmpl
        //bind events
        //form submit
        //search
        //update results
        //handle history state - onhashchange?
        if ( jQuery( '.js-e-search' ).length === 1 ) {
            e4.util.require.js( e4.settings.require.handlebars, function () {
                e4.search.handlebars.registerHelpers();
                e4.search.handlebars.compileTemplate();
                e4.search.bindEvents();
            } );
        }
    },
    bindEvents: function () {
        jQuery( '.js-e-search' ).on( 'shown.bs.modal hide.bs.modal', function ( event ) {
            e4.search.toggleFocus( this, event );
        } );
        jQuery( '.js-e-search-form' ).on( 'submit', function ( event ) {
            event.preventDefault();
        } );
        jQuery( '.js-e-search-input' ).on( 'keyup input', function ( event ) {
            //console.log( event.type );
            e4.search.toggleState( this, event );
            e4.search.results.get( this, event );
        } );
        // .on( 'change blur', function () {
        //     history.replaceState( null, null, location.href.replace( /\/(e-search)[^\/]?\//, '/e-search=' + this.value + '/' ) );
        //     console.log( this.value );
        // } );
        jQuery( '.js-e-search-clear' ).on( 'click', function () {
            e4.search.clearInput( this );
        } );
        jQuery( window ).on( 'hashchange', function () {
            if ( location.hash.indexOf( '/e-search/' ) === -1 ) {
                jQuery( '.js-e-search.in' ).modal( 'hide' );
            }
            else {
                jQuery( '.js-e-search:not(in)' ).modal( 'show' );
            }
        } );
        // if ( location.hash.indexOf( '/e-search/' ) !== -1 ) {
        //     jQuery( window ).triggerHandler( 'hashchange' );
        // }
    },
    loadIndex: function () {
        jQuery.get( jQuery( '.js-e-search-form' ).attr( 'action' ) + ( new Date().getTime() ) );
    },
    handlebars: {
        tmpl: null,
        registerHelpers: function () {
            if ( window.Handlebars.helpers.loop === undefined ) {
                Handlebars.registerHelper( 'loop', function ( items, options ) {
                    var out = '';
                    for ( var i = 0, l = items.length; i < l; i++ ) {
                        out = out + options.fn( items[ i ] );
                    }
                    return out;
                } );
            }
        },
        compileTemplate: function () {
            var tmpl = jQuery( '#js-handlebars-tmpl-search-result-item' ).html();
            e4.search.handlebars.tmpl = Handlebars.compile( tmpl );

        }
    },
    toggleFocus: function ( domElm, event ) {
        var jSearchInput = jQuery( domElm ).find( '.js-e-search-input' );
        if ( event.type === 'shown' ) {
            // if ( location.hash.indexOf( '/e-search/' ) !== -1 ) {
            //     var arrSearchQuery = location.hash.match( /\/e-search=([^\/]+)\// );
            //     if ( arrSearchQuery !== null ) {
            //         jSearchInput.val( arrSearchQuery[ 1 ] ).trigger( 'focus' ).triggerHandler( 'keyup' );
            //     }
            // }
            e4.search.loadIndex();
            jSearchInput.trigger( 'focus' );
            location.hash = '/e-search/';
        }
        else {
            jSearchInput.trigger( 'blur' );
            location.hash = location.hash.replace( '/e-search/', '' ); // location.hash.replace( '/e-search=' + this.value + '/', '' );
        }
    },
    toggleState: function ( domElm ) {
        var jSearchInputGroup = jQuery( domElm ).closest( '.js-e-search-input-group' );
        if ( domElm.value === '' ) {
            jSearchInputGroup.addClass( 'is-empty' );
        }
        else {
            jSearchInputGroup.removeClass( 'is-empty' );
        }
    },
    clearInput: function ( domElm ) {
        jQuery( domElm ).closest( '.js-e-search-input-group' ).find( '.js-e-search-input' ).val( '' ).trigger( 'focus' ).triggerHandler( 'keyup' );
    },
    results: {
        timeout: null,
        get: function ( domElm ) {
            if ( e4.search.results.timeout ) {
                e4.search.results.timeout = clearTimeout( e4.search.results.timeout );
            }
            if ( domElm.value === '' ) {
                e4.search.results.hide( domElm );
            }
            else if ( domElm.getAttribute( 'data-current-query' ) !== domElm.value ) {
                //console.log('get search - start');
                e4.search.results.timeout = setTimeout( function () {
                    domElm.setAttribute( 'data-current-query', domElm.value );
                    jQuery.getJSON( domElm.form.action + domElm.value, function ( data ) {
                        //console.log('get search - done');
                        e4.search.results.show( domElm, data );
                    } );
                }, Math.round( Math.floor( e4.settings.search.delay.min, e4.settings.search.delay.max / domElm.value.length ) ) );
            }

        },
        show: function ( domElm, data ) {
            var jSearch = jQuery( domElm ).closest( '.js-e-search' );
            jSearch.find( '.js-e-search-result-list' ).html( e4.search.handlebars.tmpl( data ) );
            jSearch.find( '.js-e-search-result-count' ).text( data.results.length );
            jSearch.find( '.js-e-search-result' ).removeClass( 'is-empty' );
        },
        hide: function ( domElm ) {
            var jSearch = jQuery( domElm ).closest( '.js-e-search' );
            jSearch.find( '.js-e-search-result' ).addClass( 'is-empty' );
            jSearch.find( '.js-e-search-result-list' ).html( '' );
        }
    }
};

