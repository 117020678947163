//https://medium.com/@dhg/parallax-done-right-82ced812e61c#.1y2cgqm4b

e4.parallax = {
    init: function () {
        e4.parallax.requestingAnimation = false;
        e4.parallax.bindEvents();
        e4.parallax.loadImages();
        e4.parallax.animate();
    },
    loadImages: function () {
        jQuery( '.js-p-img-parallax' ).each( function () {
            jQuery( this ).attr( 'src', this.getAttribute( 'data-src' ) );
        } );
    },
    bindEvents: function () {
        jQuery( '.js-p-img-parallax' ).on( 'load', function () {
            jQuery( this ).addClass( 'is-loaded' );
        } );
        jQuery( window ).on( 'scroll resize', function () {
            if ( e4.parallax.requestingAnimation === false ) {
                window.requestAnimationFrame( function () {
                    e4.parallax.animate();
                    e4.parallax.requestingAnimation = false;
                } );
            }
            e4.parallax.requestingAnimation = true;
        } );
    },
    animate: function () {
        var windowScrollTop = jQuery( window ).scrollTop();
        jQuery( '.js-p-img-parallax' ).each( function () {
            var jThis = jQuery( this );
            if ( e4.util.isInViewport( jThis ) === true ) {
                var translateY = Math.round( ( jThis.parent().offset().top - windowScrollTop ) * e4.settings.parallax.speed );
                jThis.css( {
                    'transform': 'translateY(' + translateY + 'px)'
                } );
            }
        } );
    }
};

