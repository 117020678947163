( function () {
    'use strict';
    jQuery( function () {
        e4.navigation.init();
        e4.breadcrumb.init();
        e4.forms.init();
        //e4.scrollReveal.init();
    } );
}() );

