var e4 = e4 || {};
( function () {
    'use strict';
    var jHTML = jQuery( 'html' );
    e4.settings = {
        lang: jHTML.attr( 'lang' ),
        intergerSeparator: ( function () {
            return jHTML.data( 'integer-separator' );
        }() ),
        decimalSeparator: ( function () {
            return jHTML.data( 'decimal-separator' );
        }() ),
        animation: {
            speed: 150
        },
        classes: {
            resetTransform: 'reset-transform'
        },
        navigation: {
            autoHideTolerance: {
                up: 25,
                down: 'auto'
            },
            revealActive: {
                delay: 400,
                speed: 0.75,
                easing: 'swing'
            }
        },
        parallax: {
            speed: -0.35
        },
        scrollReveal: {
            classAnimateInOnLoad: 'animate-in-on-load',
            classAnimateInOnScroll: 'animate-in-on-scroll'
        },
        scrollToAnchor: {
            speed: 0.5,
            nudge: 2,
            easing: 'swing',
            stickyElements: '.js-e-header',
            backToTop: {
                revealOffset: 1.5
            }
        },
        forms: {
            validate: {
                messages: {
                    da: {
                        required: 'Dette felt er påkrævet.',
                        maxlength: jQuery.validator.format( 'Indtast højst {0} tegn.' ),
                        minlength: jQuery.validator.format( 'Indtast mindst {0} tegn.' ),
                        rangelength: jQuery.validator.format( 'Indtast mindst {0} og højst {1} tegn.' ),
                        email: 'Indtast en gyldig email-adresse.',
                        url: 'Indtast en gyldig URL.',
                        date: 'Indtast en gyldig dato.',
                        number: 'Indtast et tal.',
                        digits: 'Indtast kun hele tal.',
                        equalTo: 'Indtast den samme værdi igen.',
                        range: jQuery.validator.format( 'Angiv en værdi mellem {0} og {1}.' ),
                        max: jQuery.validator.format( 'Angiv en værdi der højst er {0}.' ),
                        min: jQuery.validator.format( 'Angiv en værdi der mindst er {0}.' ),
                        creditcard: 'Indtast et gyldigt kreditkortnummer.'
                    }
                }
            }
        },
        search: {
            delay: {
                min: 50,
                max: 500
            }
        },
        require: {
            // jQueryValidate: {
            //     test: 'window.jQuery.fn.validate', //TODO: Make generic function to handle nemespaces in test
            //     url: '//cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.16.0/jquery.validate.min.js',
            //     fallback: '/files/system/espresso/design/js/vendor/jquery.validate.min.js',
            //     callback: function(){} // load lang from cdn
            // },
            handlebars: {
                test: 'window.Handlebars',
                url: '//cdnjs.cloudflare.com/ajax/libs/handlebars.js/4.0.5/handlebars.min.js',
                fallback: '/files/system/espresso/design/js/vendor/Handlebars-v4.0.5.min.js',
                callback: null
            }

        },
        ecom: {
            addToCart: {
                msgHideDelay: 10000
            }
        }
    };
}() );

