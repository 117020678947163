//http://upshots.org/javascript/jquery-test-if-element-is-in-viewport-visible-on-screen#h-o
e4.util = {
    isInViewport: function ( jElement ) {
        var element = jElement.get( 0 ),
            bounds = element.getBoundingClientRect();
        return bounds.top < window.innerHeight && bounds.bottom > 0;
    },
    require: {
        js: function ( script, callback ) {
            if ( eval( script.test ) === undefined ) {
                var jqXHR = jQuery.ajax( {
                    url: script.url,
                    cache: true,
                    timeout: 1000,
                    dataType: 'script'
                } );
                jqXHR.done( function () {
                    if ( typeof script.callback === 'function' ) {
                        script.callback();
                        script.callback = null;
                    }
                    if ( typeof callback === 'function' ) {
                        callback();
                    }
                } );
                jqXHR.fail( function () {
                    if ( script.fallback !== null ) {
                        script.url = script.fallback;
                        script.fallback = null;
                        e4.util.require.js( script, callback );
                    }
                } );
            }
            else {
                if ( typeof callback === 'function' ) {
                    callback();
                }
            }
        }
    },
    formatNumberToJavascript: function ( number ) {
        return parseFloat( String( number ).replace( new RegExp( '\\' + e4.settings.intergerSeparator, 'g' ), '' ).replace( new RegExp( '\\' + e4.settings.decimalSeparator, 'g' ), '.' ) );
    }
};

